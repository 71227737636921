import React from 'react';
import './App.css'
import {
  PolarChart,
  CommonSeriesSettings,
  Series,
  Export,
  Tooltip,
} from 'devextreme-react/polar-chart';
import { Ratio, PersonalData } from './data.js';

function App () {

let  date = '2023 - 2';
  
    return (
  <div> 
    <p className='title'> Skills {date} </p>
      <PolarChart
        id="chart"
        dataSource={PersonalData}
        useSpiderWeb={true}
        va
        title="     "
      >
        <CommonSeriesSettings type="line" />
        {
          Ratio.map((item) => <Series
            key={item.value}
            valueField={item.value}
            name={item.name} />)
        }
        <Export enabled={true} fileName={date}/>
        <Tooltip enabled={true}  />
      </PolarChart>
      </div>   
    );
  
}

export default App;
