export const Ratio = [{ value: "ratio", name: "ratio" },{},{},{},{},{ value: "ratio1", name: "ratio1" }];

let SenseOfHumor = 7;
let HighVibe = 7;
let StoryTelling = 5;
let EmotionalIntelligence = 4;
let CommunicationSkills = 7.5;
let SocialBattery = 5;
let Confidence =8;

let CharmingRatio =
  (SenseOfHumor +
    StoryTelling +
    HighVibe +
    EmotionalIntelligence +
    CommunicationSkills +
    SocialBattery +Confidence) /
  7;

export const PersonalData = [


  {
    arg: "Writing",
    ratio1:10,
    ratio: 5
  },

  {
    arg: "Public Speaking",
    ratio1:10,
    ratio: 6
  },
  {
    arg: "Understanding Your Purpose",
    ratio1:10,
    ratio: 4
  },
  {
    arg: "Focus",
    ratio1:10,
    ratio: 7
  },
  {
    arg: "Sense of Humor",
    ratio1:10,
    ratio: SenseOfHumor
  },
  {
    arg: "Communication Skills",
    ratio1:10,
    ratio: CommunicationSkills
  },
  
  {
    arg: "Accept Compliments",
    ratio1:10,
    ratio: 5
  },
  {
    arg: "Emotional Intelligence",
    ratio1:10,
    ratio: EmotionalIntelligence
  },
  {
    arg: "Sales",
    ratio1:10,
    ratio: 3
  },
  {
    arg: "Self-Discipline ",
    ratio1:10,
    ratio: 6
  },
  {
    arg: "Self Care ",
    ratio1:10,
    ratio: 8
  },
  {
    arg: "Story Telling",
    ratio1:10,
    ratio: StoryTelling
  },
  {
    arg: "Sexual Skills",
    ratio1:10,
    ratio: 6
  },
  {
    arg: "High Vibe",
    ratio1:10,
    ratio: HighVibe
  },
  {
    arg: "Athlete",
    ratio1:10,
    ratio: 8
  },

  {
    arg: "Talking With Girls",
    ratio1:10,
    ratio: 6.5
  },
  {
    arg: "Nutrition",
    ratio1:10,
    ratio: 8
  },
  {
    arg: "Handling Money ",
    ratio1:10,
    ratio: 4
  },
  {
    arg: "Investing",
    ratio1:10,
    ratio: 3
  },

  {
    arg: "Studying",
    ratio1:10,
    ratio: 4
  },
  {
    arg: "Confidence",
    ratio1:10,
    ratio: Confidence
  },
  {
    arg: "SocialBattery",
    ratio1:10,
    ratio: SocialBattery
  },
  {
    arg: "Charming",
    ratio1:10,
    ratio: CharmingRatio
  }
];
